import gql from 'graphql-tag';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export const BannerFragmentDoc = gql`
    fragment banner on banner {
  id
  height
  image {
    id
    filename_disk
  }
  name
  status
  sub_title
  text_alignment
  title
  type
  url
  width
}
    `;
export const Custom_MarkerFragmentDoc = gql`
    fragment custom_marker on custom_marker {
  id
  name
  status
}
    `;
export const DividerFragmentDoc = gql`
    fragment divider on divider {
  id
  name
  status
}
    `;
export const QuestionFragmentDoc = gql`
    fragment question on question {
  id
  name
  question
  answer
  status
}
    `;
export const FaqFragmentDoc = gql`
    fragment faq on faq {
  id
  name
  status
  questions {
    id
    question_id {
      ...question
    }
  }
}
    ${QuestionFragmentDoc}`;
export const LinkFragmentDoc = gql`
    fragment link on link {
  id
  display
  icon
  label
  name
  position
  status
  target
  type
  url
}
    `;
export const MediaFragmentDoc = gql`
    fragment media on media {
  id
  name
  asset_position
  body
  link {
    ...link
  }
  display
  image {
    id
    filename_disk
  }
  caption
  video
  status
}
    ${LinkFragmentDoc}`;
export const MillicastFragmentDoc = gql`
    fragment millicast on millicast {
  id
  name
  asset_position
  body
  streamId
  accountId
  status
}
    `;
export const Plain_TextFragmentDoc = gql`
    fragment plain_text on plain_text {
  id
  name
  position
  type
  value
  status
}
    `;
export const Rich_TextFragmentDoc = gql`
    fragment rich_text on rich_text {
  id
  name
  value
  status
}
    `;
export const SpacerFragmentDoc = gql`
    fragment spacer on spacer {
  id
  name
  spacer
  status
}
    `;
export const Link_FileFragmentDoc = gql`
    fragment link_file on link_file {
  id
  display
  icon
  label
  name
  position
  status
  file {
    id
    filename_disk
  }
}
    `;
export const TileFragmentDoc = gql`
    fragment tile on tile {
  id
  body
  display
  image {
    id
    filename_disk
  }
  link {
    ...link
  }
  link_file {
    ...link_file
  }
  name
  video
  status
  title
  dark_mode
}
    ${LinkFragmentDoc}
${Link_FileFragmentDoc}`;
export const Tile_SetsFragmentDoc = gql`
    fragment tile_sets on tile_sets {
  id
  grid_size
  name
  status
  tiles {
    id
    tile_id {
      ...tile
    }
  }
}
    ${TileFragmentDoc}`;
export const TitleFragmentDoc = gql`
    fragment title on title {
  id
  name
  position
  size
  status
  value
}
    `;
export const Walkthrough_LinksFragmentDoc = gql`
    fragment walkthrough_links on walkthrough_links {
  id
  name
  next {
    id
    name
  }
  prev {
    id
    name
  }
}
    `;
export const AppContentDocument = gql`
    query AppContent {
  directus {
    app {
      id
      name
      app_title
    }
    contact_us: plain_text(filter: {name: {_eq: "footer-contact-us"}}) {
      id
      value
    }
    nav_primary: menus(filter: {name: {_eq: "primary-nav"}}) {
      id
      name
      children {
        related_menus_id {
          label
          id
          name
          status
          page {
            id
            name
            title
            status
            authentication_required
          }
          children {
            id
            related_menus_id {
              id
              name
              label
              status
              page {
                id
                name
                title
                status
                authentication_required
              }
            }
          }
        }
      }
    }
    nav_footer: menus(filter: {name: {_eq: "footer-nav"}}) {
      id
      name
      label
      children {
        id
        related_menus_id {
          id
          name
          label
          status
          page {
            id
            name
            title
            status
            authentication_required
          }
          children {
            id
            related_menus_id {
              id
              name
              label
              status
              page {
                id
                name
                title
                status
                authentication_required
              }
            }
          }
        }
      }
    }
  }
}
    `;
export const AuctionAccountsDocument = gql`
    query AuctionAccounts($auctionId: Int!) {
  auctionAccounts(id: $auctionId) {
    accounts {
      id
      accountName
      accountNumber
      agencyContactNumber
      agencyName
      buyerStatus
      buyerStatusDisplayText
      isRegistered
    }
    auctionId
    auctionTitle
    insuranceMessage
    insuranceRequired
    naitRegistrationRequired
    herdCodeRequired
    participantCodeRequired
    deliveryAddressRequired
  }
}
    `;
export const AuctionByIdDocument = gql`
    query AuctionById($id: Int!) {
  auctionById(id: $id) {
    adminEmailOne
    adminEmailThree
    adminEmailTwo
    agencies {
      id
      name
      rep {
        contactPhNumber
        name
        subjectId
      }
    }
    auctionActivityStatus
    auctionNumber
    auctionStatus
    auctionUrl
    bidAccounts {
      accountName
      bidStatus
      bidderNumber
      id
    }
    description
    formatLabelId
    formatLabelText
    formatText
    id
    isReady
    isRunning
    marketOperator {
      name
      phoneNumber
    }
    messageId
    specialTermsAndConditions
    startsAt
    stockTypes
    termsAndConditionsSighted
    title
    vendors {
      id
      name
      termsAndConditionsSighted
    }
    media {
      caption
      filename
      id
      isLogo
      isPrimary
      mediaType
      metaType
      sizes {
        default
        high
        medium
      }
      thumbnailUrl
      url
    }
  }
}
    `;
export const AuctionRegisterDocument = gql`
    mutation AuctionRegister($auctionId: Int!, $userAgencyAccountId: Int!, $otherInsurance: String, $insuranceNomination: String, $naitNumber: String, $herdCode: String, $participantCode: String, $deliveryAddress: String) {
  auctionRegister(
    id: $auctionId
    userAgencyAccountId: $userAgencyAccountId
    otherInsurance: $otherInsurance
    insuranceNomination: $insuranceNomination
    naitNumber: $naitNumber
    herdCode: $herdCode
    participantCode: $participantCode
    deliveryAddress: $deliveryAddress
  ) {
    bidAccounts {
      id
      accountName
      bidStatus
      bidderNumber
    }
  }
}
    `;
export const AuctionResultByIdDocument = gql`
    query AuctionResultById($id: Int!) {
  auctionResult(id: $id) {
    auctionNumber
    activityStatus
    format
    formatLabel
    id
    lots {
      activityStatus
      averageWeight
      id
      listingId
      lotNumber
      lotType
      parentId
      price
      priceUnits
      status
      tally
      title
    }
    marketOperator {
      name
      phoneNumber
    }
    startsAt
    status
    stockTypes
    title
  }
}
    `;
export const AuctionSubscriptionDocument = gql`
    subscription AuctionSubscription($ids: [Int!]) {
  auction(where: {id: {_in: $ids}}) {
    id
    auctionstatus
    activitystatus
    startsat
  }
}
    `;
export const AuctionDocument = gql`
    subscription Auction($id: Int!) {
  auction(where: {id: {_eq: $id}}) {
    activitystatus
    adminemailone
    adminemailthree
    adminemailtwo
    auctionnumber
    auctionstatus
    changereason
    collectionaddress1
    collectionaddress2
    collectionpostalcode
    collectiontown
    createdat
    createdbyid
    description
    format
    formatlabel
    id
    increment
    insurancemessage
    insurancerequired
    lastmodifiedat
    marketoperaterid
    messageid
    naitregistrationrequired
    notes
    pricingoptions
    primarystreamname
    saletypeid
    saleyardid
    secondarystreamname
    specialtermsandconditions
    startsat
    tenantid
    termsandconditionssighted
    title
    vendortypes
  }
}
    `;
export const AuctionsPastDocument = gql`
    query AuctionsPast($from: String!, $until: String!, $auctionContentType: Int = 0) {
  pastAuctions(
    request: {from: $from, until: $until, auctionContentType: $auctionContentType}
  ) {
    auctions {
      activityStatus
      auctionFormat
      auctionFormatLabel
      auctionFormatLabelId
      auctionNumber
      auctionUrl
      bidAccounts {
        accountName
        bidStatus
        bidderNumber
        id
      }
      description
      id
      isPrivateAuctionWatcher
      isReady
      marketOperator
      numberOfFinalisedLots
      numberOfLots
      saleTypeName
      startsAt
      status
      stockTypes
      title
    }
  }
}
    `;
export const AuctionsSubscriptionDocument = gql`
    subscription AuctionsSubscription($ids: [Int!]) {
  auction(where: {id: {_in: $ids}}) {
    id
    auctionstatus
    activitystatus
    startsat
  }
}
    `;
export const AuctionUpcomingAllDocument = gql`
    query AuctionUpcomingAll($from: String!, $until: String!, $auctionContentType: Int = 0, $pageSize: Int) {
  auctions(
    request: {from: $from, until: $until, auctionContentType: $auctionContentType, pageSize: $pageSize}
  ) {
    auctions {
      id
      numberOfLots
      activityStatus
      auctionFormat
      auctionFormatLabel
      auctionFormatLabelId
      auctionNumber
      auctionUrl
      bidAccounts {
        accountName
        bidStatus
        bidderNumber
        id
      }
      description
      isPrivateAuctionWatcher
      isReady
      marketOperator
      numberOfFinalisedLots
      saleTypeName
      startsAt
      status
      stockTypes
      title
    }
  }
}
    `;
export const ListingByIdDocument = gql`
    query ListingById($auctionId: Int!, $id: Int!) {
  listingByIdAndAuctionId(auctionId: $auctionId, id: $id) {
    lot {
      activityStatus
      adminComments
      agentComments
      agencyListingCategory {
        categoryId
        categoryName
        stockTypeKey
        stockTypeName
        secondaryCategories {
          id
          value
        }
      }
      agents {
        agencyId
        agencyName
        contactPhNumber
        isAccessorCertified
        isPrimary
        name
        subjectId
      }
      assessmentDataGroups {
        data {
          fieldType
          key
          label
          maxLength
          value
          valueText
        }
        key
        name
        parentKey
      }
      assessmentStatus
      assessorName
      assessorSubjectId
      auctionId
      auctionStatus
      averageWeight
      collectionAddress1
      collectionAddress2
      collectionPostalCode
      collectionTown
      description
      id
      lotHasAgencyInterest
      lotNumber
      lotType
      maximumTally
      media {
        caption
        id
        filename
        isLogo
        isPrimary
        mediaType
        metaType
        sizes {
          default
          high
          medium
        }
        thumbnailUrl
        url
      }
      minimumTally
      nearestTown
      nearestWeighbridge
      originalAuctionListingId
      pickNotes
      referenceNumber
      referenceOrLotNumber
      region {
        id
        value
      }
      relistComments
      reservePrice
      runoutMinimumTally
      saleType
      startPrice
      status
      title
      vendorAccount {
        accountNumber
        agentBiddingSighted
        fullName
        id
        termsAndConditionsSighted
        vendorId
      }
      vendorSpecialConditions
      vendorTypes
    }
    auctionNumber
    description
    nextLot {
      activityStatus
      agency
      description
      category
      assessorName
      assessmentStatus
      averageWeight
      hasGenetics
      id
      location
      lotNumber
      lotType
      maximumTally
      mediaItems {
        caption
        id
        isLogo
        isPrimary
        mediaType
        thumbnailUrl
        url
      }
      minimumTally
      nearestWeighbridge
      order
      parentId
      primaryImageUrl
      primaryRepName
      primaryRepPhoneNumber
      referenceOrLotNumber
      secondaryRepNames
      stockType
      title
      vendorLogoUrl
    }
    title
    previousLot {
      activityStatus
      agency
      assessmentStatus
      assessorName
      averageWeight
      category
      description
      hasGenetics
      id
      location
      lotNumber
      lotType
      maximumTally
      mediaItems {
        caption
        id
        isLogo
        isPrimary
        mediaType
        thumbnailUrl
        url
      }
      minimumTally
      nearestWeighbridge
      order
      parentId
      primaryImageUrl
      primaryRepName
      primaryRepPhoneNumber
      referenceOrLotNumber
      secondaryRepNames
      stockType
      title
      vendorLogoUrl
    }
  }
}
    `;
export const ListingsByAuctionIdDocument = gql`
    query ListingsByAuctionId($id: Int!) {
  listingsByAuctionId(id: $id) {
    lots {
      activityStatus
      agency
      assessmentStatus
      assessmentData {
        TbStatus
        WithholdingTreatment
        VendorName
      }
      assessorName
      averageWeight
      category
      description
      hasGenetics
      id
      location
      lotNumber
      lotType
      maximumTally
      mediaItems {
        caption
        id
        isLogo
        isPrimary
        mediaType
        thumbnailUrl
        url
      }
      minimumTally
      nearestWeighbridge
      parentId
      pickNotes
      primaryImageUrl
      primaryRepName
      primaryRepPhoneNumber
      referenceOrLotNumber
      stockType
      title
      vendorLogoUrl
    }
  }
}
    `;
export const BuyerProfileDocument = gql`
    query BuyerProfile($id: String!) {
  buyerProfileById(id: $id) {
    activeStatus
    address1
    address2
    agencies {
      accountName
      accountNumber
      agencyId
      agencyListed
      creditStatus
      creditStatusDisplayText
      fullAccountNumber
      id
    }
    city
    country
    emailAddress
    firstName
    landLine
    lastName
    legalEntityName
    marketingOptIn
    marketingOptions
    mobileNumber
    poBox
    postCode
    postalCity
    termsAndConditions
    tradingName
    naitNumbers
    herdCodes
    participantCodes
    deliveryAddresses
  }
}
    `;
export const BuyerProfileUpdateDocument = gql`
    mutation BuyerProfileUpdate($subjectId: String!, $firstName: String!, $lastName: String!, $emailAddress: String!, $tradingName: String!, $mobileNumber: String!, $landLine: String, $address1: String!, $address2: String, $postCode: Int!, $city: String!, $country: String!, $legalEntityName: String!, $termsAndConditions: Boolean!, $agencies: [Agency], $marketingOptions: Int!, $naitNumbers: String!, $herdCodes: String!, $participantCodes: String!, $deliveryAddresses: String!) {
  userUpdate(
    subjectId: $subjectId
    firstName: $firstName
    lastName: $lastName
    emailAddress: $emailAddress
    tradingName: $tradingName
    mobileNumber: $mobileNumber
    landLine: $landLine
    address1: $address1
    address2: $address2
    postCode: $postCode
    city: $city
    country: $country
    legalEntityName: $legalEntityName
    termsAndConditions: $termsAndConditions
    agencies: $agencies
    marketingOptions: $marketingOptions
    naitNumbers: $naitNumbers
    herdCodes: $herdCodes
    participantCodes: $participantCodes
    deliveryAddresses: $deliveryAddresses
  ) {
    address1
    address2
    agencies {
      accountName
      accountNumber
      agencyId
      agencyListed
      creditStatus
      creditStatusDisplayText
      fullAccountNumber
      id
    }
    city
    country
    emailAddress
    firstName
    landLine
    lastName
    legalEntityName
    marketingOptions
    mobileNumber
    postCode
    termsAndConditions
    tradingName
    naitNumbers
    herdCodes
    participantCodes
    deliveryAddresses
  }
}
    `;
export const UserUpdateCodesDocument = gql`
    mutation UserUpdateCodes($subjectId: String!, $codeType: String!, $codes: String!) {
  userUpdateCodes(subjectId: $subjectId, codeType: $codeType, codes: $codes)
}
    `;
export const CatchAllPagesDocument = gql`
    query CatchAllPages($name: String!) {
  directus {
    page(filter: {name: {_eq: $name}}) {
      id
      name
      title
      status
      intro
      header {
        ...banner
      }
      main(sort: "sort") {
        id
        collection
        sort
        item {
          ... on title {
            ...title
          }
          ... on tile_sets {
            ...tile_sets
          }
          ... on custom_marker {
            ...custom_marker
          }
          ... on plain_text {
            ...plain_text
          }
          ... on rich_text {
            ...rich_text
          }
          ... on media {
            ...media
          }
          ... on millicast {
            ...millicast
          }
          ... on link {
            ...link
          }
          ... on link_file {
            ...link_file
          }
          ... on walkthrough_links {
            ...walkthrough_links
          }
          ... on faq {
            ...faq
          }
          ... on divider {
            ...divider
          }
          ... on spacer {
            ...spacer
          }
        }
      }
      aside(sort: "sort") {
        id
        collection
        sort
        item {
          ... on title {
            ...title
          }
          ... on tile_sets {
            ...tile_sets
          }
          ... on divider {
            ...divider
          }
          ... on plain_text {
            ...plain_text
          }
          ... on link {
            ...link
          }
          ... on link_file {
            ...link_file
          }
        }
      }
      secondary_main(sort: "sort") {
        id
        collection
        sort
        item {
          ... on title {
            ...title
          }
          ... on tile_sets {
            ...tile_sets
          }
          ... on custom_marker {
            ...custom_marker
          }
          ... on plain_text {
            ...plain_text
          }
          ... on rich_text {
            ...rich_text
          }
          ... on media {
            ...media
          }
          ... on link {
            ...link
          }
          ... on link_file {
            ...link_file
          }
          ... on faq {
            ...faq
          }
          ... on divider {
            ...divider
          }
          ... on spacer {
            ...spacer
          }
        }
      }
      secondary_aside(sort: "sort") {
        id
        collection
        sort
        item {
          ... on title {
            ...title
          }
          ... on tile_sets {
            ...tile_sets
          }
          ... on divider {
            ...divider
          }
          ... on link {
            ...link
          }
          ... on link_file {
            ...link_file
          }
        }
      }
      authentication_required
      meta_title
      meta_description
      meta_robots_no_index
      social_title
      social_description
      social_image {
        id
        filename_disk
      }
    }
  }
}
    ${BannerFragmentDoc}
${TitleFragmentDoc}
${Tile_SetsFragmentDoc}
${Custom_MarkerFragmentDoc}
${Plain_TextFragmentDoc}
${Rich_TextFragmentDoc}
${MediaFragmentDoc}
${MillicastFragmentDoc}
${LinkFragmentDoc}
${Link_FileFragmentDoc}
${Walkthrough_LinksFragmentDoc}
${FaqFragmentDoc}
${DividerFragmentDoc}
${SpacerFragmentDoc}`;
export const HomePageDocument = gql`
    query HomePage {
  directus {
    page(filter: {name: {_eq: "home"}}) {
      id
      name
      title
      status
      intro
      header {
        ...banner
      }
      main(sort: "sort") {
        id
        collection
        sort
        item {
          ... on title {
            id
            name
            position
            size
            status
            value
          }
          ... on tile_sets {
            ...tile_sets
          }
          ... on custom_marker {
            ...custom_marker
          }
          ... on plain_text {
            ...plain_text
          }
          ... on rich_text {
            ...rich_text
          }
          ... on media {
            ...media
          }
          ... on millicast {
            ...millicast
          }
          ... on link {
            ...link
          }
          ... on walkthrough_links {
            ...walkthrough_links
          }
          ... on faq {
            ...faq
          }
          ... on divider {
            ...divider
          }
          ... on spacer {
            ...spacer
          }
        }
      }
      aside(sort: "sort") {
        id
        collection
        sort
        item {
          ... on title {
            ...title
          }
          ... on tile_sets {
            ...tile_sets
          }
          ... on divider {
            ...divider
          }
          ... on plain_text {
            ...plain_text
          }
          ... on link {
            ...link
          }
        }
      }
      secondary_main(sort: "sort") {
        id
        collection
        sort
        item {
          ... on title {
            ...title
          }
          ... on tile_sets {
            ...tile_sets
          }
          ... on custom_marker {
            ...custom_marker
          }
          ... on plain_text {
            ...plain_text
          }
          ... on rich_text {
            ...rich_text
          }
          ... on media {
            ...media
          }
          ... on link {
            ...link
          }
          ... on faq {
            ...faq
          }
          ... on divider {
            ...divider
          }
          ... on spacer {
            ...spacer
          }
        }
      }
      secondary_aside(sort: "sort") {
        id
        collection
        sort
        item {
          ... on title {
            ...title
          }
          ... on tile_sets {
            ...tile_sets
          }
          ... on divider {
            ...divider
          }
          ... on link {
            ...link
          }
        }
      }
      authentication_required
      meta_title
      meta_description
      meta_robots_no_index
      social_title
      social_description
      social_image {
        id
        filename_disk
      }
    }
  }
}
    ${BannerFragmentDoc}
${Tile_SetsFragmentDoc}
${Custom_MarkerFragmentDoc}
${Plain_TextFragmentDoc}
${Rich_TextFragmentDoc}
${MediaFragmentDoc}
${MillicastFragmentDoc}
${LinkFragmentDoc}
${Walkthrough_LinksFragmentDoc}
${FaqFragmentDoc}
${DividerFragmentDoc}
${SpacerFragmentDoc}
${TitleFragmentDoc}`;
export const CheckEmailExistDocument = gql`
    query CheckEmailExist($email: String!) {
  emailExists(email: $email)
}
    `;
export const GetAgenciesDocument = gql`
    query GetAgencies {
  agencies {
    agencies {
      abbreviation
      address1
      address2
      address3
      contacts {
        emailAddress
        id
        lotEmailAddress
        name
        phoneNumber
        title
      }
      id
      listed
      name
      status
    }
  }
}
    `;
export const SignupDocument = gql`
    mutation Signup($firstName: String!, $lastName: String!, $emailAddress: String!, $tradingName: String!, $mobileNumber: String!, $landLine: String, $address1: String!, $address2: String, $postCode: Int!, $city: String!, $country: String!, $legalEntityName: String!, $termsAndConditions: Boolean!, $agencies: [Agency], $marketingOptions: Int!, $naitNumbers: String, $herdCodes: String, $participantCodes: String, $deliveryAddresses: String) {
  userCreate(
    address1: $address1
    city: $city
    country: $country
    emailAddress: $emailAddress
    firstName: $firstName
    lastName: $lastName
    legalEntityName: $legalEntityName
    marketingOptions: $marketingOptions
    postCode: $postCode
    termsAndConditions: $termsAndConditions
    tradingName: $tradingName
    mobileNumber: $mobileNumber
    address2: $address2
    agencies: $agencies
    landLine: $landLine
    naitNumbers: $naitNumbers
    herdCodes: $herdCodes
    participantCodes: $participantCodes
    deliveryAddresses: $deliveryAddresses
  )
}
    `;
export const GetRegistrationDocument = gql`
    query GetRegistration($token: String!) {
  userRegistration(token: $token) {
    agencyEmail
    newUserEmail
    invitationStatus
  }
}
    `;
export const ConfirmAccountDocument = gql`
    mutation ConfirmAccount($username: String!, $password: String!, $token: String!) {
  userConfirm(password: $password, token: $token, username: $username) {
    subjectId
  }
}
    `;
export const UserProfileDocument = gql`
    query UserProfile($userID: String!) {
  userById(id: $userID) {
    agencyId
    emailAddress
    firstName
    isAccessorCertified
    lastName
    username
    permissions {
      key
    }
    role {
      id
      key
      name
    }
    accreditations {
      stockTypeKey
    }
  }
}
    `;